 $orange-red-crayola:    #FF5C5C;
 $raisin-black:          #23282F;
 $snow:                  #FFF3F3;
 $powder-blue:           #A8DADC;
 
 $tart-orange:           #FF3333;
 $white:                 #FDFDFD;
 $cultured:              #F3F5F7;
 $honeydew:              #F1FAEE;
 $celadon-blue:          #457B9D; 
